._1Q3wdQQiYz {
  margin-top: 0;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  ._1Q3wdQQiYz {
    background: none;
    margin-right: 0;
  }
}
._3xnoE0mqoR {
  margin-bottom: 2rem;
}
