@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(./source-sans-pro-v13-latin_latin-ext-regular.37a8ca9b9d147d1617ab009212651e1d.eot);
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(./source-sans-pro-v13-latin_latin-ext-regular.37a8ca9b9d147d1617ab009212651e1d.eot?#iefix) format('embedded-opentype'), url(./source-sans-pro-v13-latin_latin-ext-regular.80c998aa03640281d556814a14e1d9c8.woff2) format('woff2'), url(./source-sans-pro-v13-latin_latin-ext-regular.d683bfb394f888c5820ce11fc4770fd8.woff) format('woff'), url(./source-sans-pro-v13-latin_latin-ext-regular.054403219b0b4b43a21d9dddd8e6a422.ttf) format('truetype'), url(./source-sans-pro-v13-latin_latin-ext-regular.3d536f380466c415ea74e04b54dcc087.svg#SourceSansPro) format('svg');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(./source-sans-pro-v13-latin_latin-ext-600.83fcc4517ace3fe46413e0423f95df4d.eot);
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(./source-sans-pro-v13-latin_latin-ext-600.83fcc4517ace3fe46413e0423f95df4d.eot?#iefix) format('embedded-opentype'), url(./source-sans-pro-v13-latin_latin-ext-600.117e12cdb861ed7356c805f6f515afbb.woff2) format('woff2'), url(./source-sans-pro-v13-latin_latin-ext-600.3d34f60e200a8cbb1816b3f2ee762431.woff) format('woff'), url(./source-sans-pro-v13-latin_latin-ext-600.f3d848e7fb4d51a4108f42838a8dfa3e.ttf) format('truetype'), url(./source-sans-pro-v13-latin_latin-ext-600.dd7360604912cf0bb7703702efa619c7.svg#SourceSansPro) format('svg');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(./source-sans-pro-v13-latin_latin-ext-700.b3156912c96cb39cbee27e793a6eb25e.eot);
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(./source-sans-pro-v13-latin_latin-ext-700.b3156912c96cb39cbee27e793a6eb25e.eot?#iefix) format('embedded-opentype'), url(./source-sans-pro-v13-latin_latin-ext-700.639c2738552a0376c91e7d485e476fda.woff2) format('woff2'), url(./source-sans-pro-v13-latin_latin-ext-700.1e78dc5b59a9ed2a24cd885b74cc0803.woff) format('woff'), url(./source-sans-pro-v13-latin_latin-ext-700.6c142c18187b874f0b122714a5efc25a.ttf) format('truetype'), url(./source-sans-pro-v13-latin_latin-ext-700.c1c09b296df000fd064aacb10d4499cd.svg#SourceSansPro) format('svg');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(./ibm-plex-mono-regular.7eaa73b051705f3947ae08b99babe933.eot);
  src: local('IBM Plex Mono'), local('IBMPlexMono'), url(./ibm-plex-mono-regular.7eaa73b051705f3947ae08b99babe933.eot?#iefix) format('embedded-opentype'), url(./ibm-plex-mono-regular.762415236b4a11831f9fc064ea48dbdb.woff2) format('woff2'), url(./ibm-plex-mono-regular.0c3db5c13d8ffb2ddd28b9a42a59dc65.woff) format('woff'), url(./ibm-plex-mono-regular.fc89cc270cd790199d45a4228bb4e18a.ttf) format('truetype'), url(./ibm-plex-mono-regular.6463d901c7483d679171eabbce1e26d5.svg#IBMPlexMono) format('svg');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./materialicons.13a768c54630377e6c74a51096c9c6ef.woff2) format('woff2');
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
details,
section,
summary,
aside,
main,
menu,
nav,
figcaption,
figure,
footer,
header,
hgroup {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
b,
strong {
  font-weight: bold;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
html,
body {
  font-size: 14px;
  height: 100%;
}
body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 1.6786;
  color: #363636;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}
#app {
  height: 100%;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-track {
  padding: 2px;
}
h1 {
  font-size: 1.857rem;
  line-height: 1.2;
  font-weight: 600;
}
h2 {
  font-size: 1.57rem;
  line-height: 1.4;
  font-weight: 800;
}
h3 {
  font-size: 1.286rem;
  line-height: 1.6;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
  line-height: 1.6786;
  font-weight: 600;
}
ul li:not(:last-child) {
  margin-bottom: 0.75rem;
}
ul li a {
  text-decoration: none;
  color: #363636;
}
hr {
  border: 1px solid #efefef;
  background-color: #efefef;
  border: 0;
  height: 1px;
}
code {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
  background-color: #ececec;
  padding: 0.1rem 0.25rem;
  color: #000;
}
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}
