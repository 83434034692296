._2hKPQ6OLA9 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 0;
  scrollbar-width: none;
}
._2hKPQ6OLA9::-webkit-scrollbar {
  display: none;
}
._2hKPQ6OLA9 ._2ZKJdTa57R {
  margin-right: 0.5rem;
}
._3ebFDBdOir {
  border-bottom: 1px solid #efefef;
}
