._2xpx8J-Rov {
  border-bottom: 1px solid #efefef;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  ._2xpx8J-Rov {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 550px) {
  .omjnfU9KmB {
    margin-right: -1rem;
    overflow: auto;
    background: -webkit-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -moz-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -o-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -ms-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: linear-gradient(to left, rgba(0,0,0,0.1), #fff 5rem);
  }
}
