._2OrPGwJh1T {
  width: 100%;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._2OrPGwJh1T._15LJzgpc3E {
  max-width: 5rem;
}
._2OrPGwJh1T._3hhC5tf5u2 {
  max-width: 8rem;
}
._2OrPGwJh1T._2X1spcB1X6 {
  max-width: 14rem;
}
._2OrPGwJh1T._2j4AeUzQZr {
  max-width: 30rem;
}
._2OrPGwJh1T._1Bc4BWS4G6 {
  max-width: 40rem;
}
._2OrPGwJh1T.ob3NqIWeYZ {
  max-width: none;
}
._2OrPGwJh1T ._19rpw3xwAy {
  -webkit-flex-basis: 30%;
  flex-basis: 30%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  min-width: 9rem;
}
._2OrPGwJh1T .TDdWdUWKDu {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding-right: 0.25rem;
  min-width: 0;
}
._2OrPGwJh1T ._19rpw3xwAy + .TDdWdUWKDu {
  -webkit-flex-basis: auto;
  flex-basis: auto;
}
._23IZzwEdXE {
  width: 100%;
}
