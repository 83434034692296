.N_dGeFWtAg {
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  -webkit-transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
}
._3G4Te2l2_v {
  visibility: visible;
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=85);
  z-index: 10;
}
._2_pvIhXTiE {
  position: relative;
}
._7zUhZ81AIU {
  z-index: 11;
}
