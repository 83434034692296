._3xvuzgCylu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 1.5rem;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._3xvuzgCylu ._3UnRF5RUzI:last-child {
  margin-left: 1rem;
}
._3UnRF5RUzI {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
._3UnRF5RUzI ._1t2hHl6xFx {
  position: relative;
  bottom: 1px;
  color: #3d73ff;
}
.EfnnKTTXt0 {
  margin-left: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media screen and (max-width: 620px) {
  .EfnnKTTXt0 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.KVjMvsA2Lf {
  white-space: nowrap;
}
.KVjMvsA2Lf .h9amKlQTck {
  margin-left: 0.25rem;
}
