._3jR67IfRDa {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
._3jR67IfRDa:not(._3bPoSIgy2E) .select__value-container {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600;
  font-size: 0.857rem;
}
._3jR67IfRDa ._2x1mN61LIQ {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  margin-bottom: 0.5rem;
}
._3jR67IfRDa ._2x1mN61LIQ .select__option:not(:first-child) {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600;
  font-size: 0.857rem;
}
._3jR67IfRDa ._3Rrx3cPY8V {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
._1wDCngIDt0 {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
}
@media screen and (min-width: 769px) {
  ._3jR67IfRDa {
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -o-box-lines: single;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  ._3jR67IfRDa ._2x1mN61LIQ {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  ._3jR67IfRDa ._2x1mN61LIQ + ._3Rrx3cPY8V {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
  }
}
