._2pIX1vGDWd {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.75rem 0;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
._2pIX1vGDWd.haElFEvdjH {
  border-bottom: 1px solid #efefef;
  padding-bottom: 0;
}
._2pIX1vGDWd.haElFEvdjH ._2hMgOQej-R {
  padding-bottom: 0;
}
@media screen and (max-width: 620px) {
  ._2pIX1vGDWd.haElFEvdjH ._2hMgOQej-R {
    padding-bottom: 0.75rem;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
  ._2pIX1vGDWd.haElFEvdjH ._2ymgsMfzAX {
    border-bottom: 1px solid #efefef;
  }
  ._2pIX1vGDWd.haElFEvdjH.haElFEvdjH {
    border: none;
  }
}
._2hMgOQej-R {
  -webkit-flex-basis: auto;
  flex-basis: auto;
  padding: 0.25rem 0.75rem 0.25rem 0;
}
._2jRPcFX6lt {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  max-width: 32rem;
}
@media screen and (max-width: 620px) {
  ._2jRPcFX6lt {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    max-width: none;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
  ._2jRPcFX6lt ._59oNxbub14 {
    width: 100%;
  }
}
._59oNxbub14 {
  min-width: 5rem;
}
._59oNxbub14:not(:last-child) {
  margin-right: 0.75rem;
}
._2JdGo5gatR {
  margin-top: 2rem;
}
._2ymgsMfzAX {
  margin: 0;
}
._20klNtj4-g {
  padding-left: 1rem;
  color: #363636;
}
@media screen and (max-width: 620px) {
  ._20klNtj4-g {
    padding-bottom: 0.75rem;
    padding-left: 0;
  }
}
._3-RAC3rcdE {
  z-index: 11;
  position: absolute;
  top: 5rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  color: #ea0000;
}
