._26evQTABDZ {
  border-bottom: 1px solid #dfdfdf;
}
._2BV1BszzE7 {
  border-bottom: 1px solid #efefef;
}
._1KuhkTyLl5 {
  cursor: pointer;
  -webkit-transition: background-color 0.12s linear;
  -moz-transition: background-color 0.12s linear;
  -o-transition: background-color 0.12s linear;
  -ms-transition: background-color 0.12s linear;
  transition: background-color 0.12s linear;
}
._1KuhkTyLl5:hover {
  background-color: #fafafa;
}
