._284r2HpLaD {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
._284r2HpLaD:not(:last-child) {
  margin-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  ._284r2HpLaD:not(:last-child) {
    margin-right: 0.5rem;
  }
}
._284r2HpLaD ._2y3oCuW2lC {
  color: #363636;
}
._284r2HpLaD._1GvewOaN12 ._1OdQMVJWqi,
._284r2HpLaD._1GvewOaN12 ._2d50a2Yhlf,
._284r2HpLaD._1GvewOaN12 ._2y3oCuW2lC {
  color: #ff5c5c;
}
._284r2HpLaD._1GvewOaN12 ._2y3oCuW2lC {
  font-weight: 600;
}
._1OdQMVJWqi {
  position: relative;
  bottom: 2px;
  color: #686868;
  margin-right: 0.5rem;
}
._2d50a2Yhlf {
  color: #363636;
  font-weight: 600;
  margin-right: 0.25rem;
}
@media screen and (max-width: 768px) {
  ._284r2HpLaD:not(._1GvewOaN12) ._2y3oCuW2lC {
    display: none;
  }
}
