._2uElReSK3U {
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
._2PnRrKwjTR {
  margin-bottom: 1rem;
}
.KS8G9ej5oT {
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: initial;
  color: #363636;
  text-align: center;
  display: block;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 2rem;
  cursor: pointer;
  -webkit-transform: scale(1.0001);
  -moz-transform: scale(1.0001);
  -o-transform: scale(1.0001);
  -ms-transform: scale(1.0001);
  transform: scale(1.0001);
  background: #fff;
  -webkit-box-shadow: 0 4px 30px 0 rgba(0,0,0,0.08), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.08), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  border-radius: 12px;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -moz-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -moz-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -o-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -o-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -ms-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -ms-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
}
@media screen and (max-width: 768px) {
  .KS8G9ej5oT {
    -webkit-box-shadow: 0 4px 30px 0 rgba(0,0,0,0.14), inset 0 -1px 0 0 rgba(0,0,0,0.11);
    box-shadow: 0 4px 30px 0 rgba(0,0,0,0.14), inset 0 -1px 0 0 rgba(0,0,0,0.11);
    padding: 1.5rem 0.8rem;
  }
}
@media screen and (max-width: 1080px) {
  .KS8G9ej5oT {
    padding: 2rem 2rem;
  }
}
.KS8G9ej5oT:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 4px 55px 0 rgba(0,0,0,0.1), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  box-shadow: 0 4px 55px 0 rgba(0,0,0,0.1), inset 0 -1px 0 0 rgba(0,0,0,0.11);
}
.KS8G9ej5oT .Wict4wKjqS {
  display: block;
  font-size: 1.286rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 768px) {
  .KS8G9ej5oT .Wict4wKjqS {
    font-size: 1rem;
  }
}
.KS8G9ej5oT .fkVDUzkHiy {
  display: block;
  line-height: 1.3;
}
.KS8G9ej5oT ._2trg3xtKCE {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1rem;
  width: 100%;
  height: 10rem;
  object-fit: contain;
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .KS8G9ej5oT ._2trg3xtKCE {
    padding: 0;
    height: 7rem;
  }
}
