._1Lf-ttT42S {
  padding: 0 0.5rem 0.5rem;
}
.l59WQ-4dB_ {
  overflow: auto;
}
._25ntFYG0hI {
  margin-bottom: calc(1rem - 0.570724rem);
}
._3FJWdX0fta {
  margin-top: 0;
}
