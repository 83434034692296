._1WEd8eZG1f:not(:last-child) {
  margin-bottom: 3rem;
}
._1Rw4m4mE1S {
  margin-bottom: 0.25rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}
._1mtByKaW0a {
  font-weight: 600;
}
._2c-9rKmpHz {
  text-decoration: none;
}
._2-BsTkaMRh {
  color: inherit;
}
