._2y5IlrrwDE {
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
._2y5IlrrwDE._1rMGdNVoAM {
  display: block;
}
._2y5IlrrwDE li {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
._2y5IlrrwDE li:last-child {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
@media screen and (max-width: 620px) {
  ._2y5IlrrwDE {
    display: block;
  }
}
