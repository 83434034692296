._1iTAwX9PSu {
  border-bottom: 1px solid #efefef;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  ._1iTAwX9PSu {
    margin-bottom: 1.5rem;
  }
}
._14TkSwZnhM {
  margin-top: 6rem;
}
@media screen and (max-width: 768px) {
  ._14TkSwZnhM {
    margin-top: 1.5rem;
  }
}
._3OrH4wmVBn {
  margin-right: 1.5rem;
}
