._1XRs5OLLkO {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-items: flex-start;
  margin-bottom: 0.25rem;
}
._3Nn-oFc1MH {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
._2slSa7eBFc {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 2.3rem;
  margin-left: 1rem;
}
._2slSa7eBFc .pKVTmeDu8V {
  margin-left: 0.5rem;
}
._301LPOlvFe {
  color: #ff5c5c;
}
._1dqMszIppn {
  color: #ff5c5c;
  margin-right: 0.25rem;
}
.qzjEHowcWT {
  color: #868686;
}
._2VSWLMpIoB {
  height: 8rem;
  width: 8rem;
  object-fit: contain;
  margin-right: 1rem;
}
