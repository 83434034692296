._35Y1DcAyB7:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
._2dvocfe6mp {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
}
._1dQDLT_r8X {
  padding-right: 0.75rem;
}
._2dvocfe6mp .n8gHK1-yUe {
  margin-top: 0;
}
._2dvocfe6mp .YMx-0kHEfD {
  margin-top: calc(0rem - 0.570724rem);
}
._2dvocfe6mp ._1dkivzUbKW {
  min-width: 5.5rem;
}
._17Z3ShDC2E {
  display: block;
  padding-bottom: 2rem;
}
