._3ATl9ePrPv {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border: 1px solid #dfdfdf;
}
@media screen and (min-width: 621px) {
  ._3ATl9ePrPv {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 48rem;
    padding: 1rem 1.5rem 1rem 1rem;
  }
}
@media screen and (max-width: 620px) {
  ._3ATl9ePrPv {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }
}
.pUNQACaTvD {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
._2V3tvX2u9z {
  object-fit: contain;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
@media screen and (min-width: 621px) {
  ._2V3tvX2u9z {
    padding-right: 1rem;
    width: 10rem;
    max-height: 8.5rem;
  }
}
@media screen and (max-width: 620px) {
  ._2V3tvX2u9z {
    padding-right: 0;
    margin: 0 auto;
    width: auto;
    margin-bottom: 1rem;
    max-height: 10rem;
  }
}
._2jF6Q4rVbZ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.c6kbC5nVhM:not(:last-child) {
  padding-right: 0.25rem;
}
.c6kbC5nVhM:not(:last-child):after {
  content: ',';
}
.c6kbC5nVhM:not(:last-child):last-child {
  padding: 0;
}
.c6kbC5nVhM:not(:last-child):last-child:after {
  display: none;
}
._1C5AxN-9Q2 {
  margin-top: calc(1.5rem - 0.570724rem);
}
._3tAQeSBhd9 {
  margin-top: 1rem;
}
