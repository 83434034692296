._1epeSbC28Y {
  padding: 1rem;
}
@media screen and (max-width: 768px) {
  ._1epeSbC28Y {
    padding: 1rem 0.5rem;
  }
}
._1epeSbC28Y:first-child,
._1epeSbC28Y:last-child {
  padding-right: 0.75rem;
}
._1epeSbC28Y:first-child {
  font-weight: 600;
}
._1epeSbC28Y:last-child:not(._2sWnkaRy7m) {
  text-align: right;
}
._2H_0fWvi6J {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
._2sWnkaRy7m {
  text-align: center;
}
._3Hq1HQO9Zl {
  font-weight: normal;
  font-size: 0.857rem;
  color: #868686;
}
._3e5Pkue_i6 {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
._3Hq1HQO9Zl:not(._2sWnkaRy7m) {
  text-align: left;
}
