._2HbgOvb9of {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
._2HbgOvb9of ._1NgCU1WxLV {
  width: auto;
  padding-right: 0.75rem;
}
._2HbgOvb9of ._1NgCU1WxLV._2a2HOiF5YR {
  padding-right: 0;
}
._2HbgOvb9of ._2HQG4E9Mcs {
  -webkit-flex-basis: 8rem;
  flex-basis: 8rem;
}
._2HbgOvb9of ._1LmUU5wyk- {
  -webkit-flex-basis: 14rem;
  flex-basis: 14rem;
}
@media screen and (max-width: 768px) {
  ._2HbgOvb9of {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  ._2HbgOvb9of ._1NgCU1WxLV {
    padding-right: 0;
  }
  ._2HbgOvb9of ._2HQG4E9Mcs,
  ._2HbgOvb9of ._1LmUU5wyk- {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
}
._3vbH_fMwk3 {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
