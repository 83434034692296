._1e6afbj80J {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  color: #868686;
  min-height: 18px;
}
._1e6afbj80J:not(:last-child):after {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: middle;
  font-feature-settings: 'liga';
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 0.25rem;
  content: 'keyboard_arrow_right';
  color: #868686;
}
._1e6afbj80J.focus-visible {
  text-decoration: underline;
  color: #363636;
}
._1e6afbj80J:focus {
  outline: 0;
}
._1zX54f3GdE {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  color: #363636;
  min-height: 18px;
}
@media screen and (max-width: 768px) {
  ._1zX54f3GdE:last-child {
    padding-right: 1rem;
  }
}
