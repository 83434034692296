.UPR1oNIiNx {
  margin-top: 4rem;
}
.UPR1oNIiNx span {
  position: relative;
  bottom: 4px;
  color: #ff5c5c;
  margin-right: 0.75rem;
}
.UPR1oNIiNx h2 {
  display: inline;
}
._1pr7ewM2pz {
  margin-top: 3rem;
}
