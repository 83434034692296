._1FlMsrkv30 {
  -webkit-flex-basis: 65%;
  flex-basis: 65%;
  padding-right: 1rem;
  overflow-y: scroll;
  border-right: 1px solid #efefef;
}
@media screen and (max-width: 768px) {
  ._1FlMsrkv30 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -o-box-flex: 0;
    -ms-box-flex: 0;
    box-flex: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    margin-bottom: 3rem;
    border: 0;
  }
}
._1FlMsrkv30 h4 {
  margin-top: 0;
}
._1FlMsrkv30 ul {
  margin-top: calc(0rem - 0.570724rem);
  padding-left: 0;
}
._1FlMsrkv30 li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
._1FlMsrkv30 li > span {
  position: relative;
  top: 3px;
  color: #3d73ff;
  margin-right: 0.25rem;
}
._1FlMsrkv30 ._1tvnhz4gP7 {
  color: #363636;
}
.T-OK23KVIH ._25MaDuckHn {
  width: 15rem;
}
.T-OK23KVIH .qI1yBewDxp {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .T-OK23KVIH {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -o-box-flex: 0;
    -ms-box-flex: 0;
    box-flex: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
  }
  .T-OK23KVIH ._25MaDuckHn {
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .T-OK23KVIH {
    -webkit-flex-basis: 35%;
    flex-basis: 35%;
    padding-left: 3rem;
  }
}
