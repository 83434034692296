._2Xvs9kAoJZ {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  padding: 0.75rem;
  margin-left: -0.75rem;
  margin-bottom: 1rem;
}
._2cOxABKhQG {
  margin: 0;
  margin-right: 0.5rem;
  display: inline-block;
  color: #000;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
._1RNEVzaN3a {
  color: #686868;
  font-size: 1.57rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
._3JwOBsMy1I {
  display: none;
  overflow: hidden;
}
._3JwOBsMy1I._3udG3fhRlH {
  display: block;
  overflow: initial;
}
