._2_UCi4bMJO {
  border: 1px solid #dfdfdf;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #fff;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  color: #363636;
}
._2_UCi4bMJO:not(:first-child) {
  margin-left: 0.25rem;
}
