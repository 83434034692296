._3Z4mCvMufw {
  border-bottom: 1px solid #efefef;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  ._3Z4mCvMufw {
    margin-bottom: 1.5rem;
  }
}
._1iZ7iHV7SK {
  color: #868686;
}
