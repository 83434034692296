._3CiR4pjruW {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
._3CiR4pjruW > li:not(:last-child) {
  margin-bottom: 0;
}
._3CiR4pjruW > li {
  -webkit-transition: height 0.25s ease-in-out;
  -moz-transition: height 0.25s ease-in-out;
  -o-transition: height 0.25s ease-in-out;
  -ms-transition: height 0.25s ease-in-out;
  transition: height 0.25s ease-in-out;
}
.gvmfkIaghm > li {
  height: 0;
  -webkit-transition: visibility 0.25s, height 0.25s ease-in-out;
  -moz-transition: visibility 0.25s, height 0.25s ease-in-out;
  -o-transition: visibility 0.25s, height 0.25s ease-in-out;
  -ms-transition: visibility 0.25s, height 0.25s ease-in-out;
  transition: visibility 0.25s, height 0.25s ease-in-out;
}
.gvmfkIaghm:not(._3c10zlVXpd) > li {
  visibility: hidden;
}
._3c10zlVXpd > li {
  height: 3.1rem;
}
._3c10zlVXpd li a,
.gvmfkIaghm li a {
  padding-left: 3rem;
}
._3IxevrAIzW {
  margin-right: 0.5rem;
}
