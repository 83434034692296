._3ZFDHx4ryG {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: middle;
  font-feature-settings: 'liga';
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._3ZFDHx4ryG.h37PY0HbhQ {
  font-size: 1rem;
}
._3ZFDHx4ryG._2Qwx7yLPP5 {
  font-size: 2rem;
}
._3ZFDHx4ryG._1MVgVzYP5l {
  position: relative;
  top: -1px;
}
._3ZFDHx4ryG._2-BI5zisWK {
  position: relative;
  top: 1px;
}
