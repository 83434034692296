._3RsT1IT38w {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 1px;
}
