._1vqq4U7ARu {
  display: none;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
ul._1p3i4Cxjxu {
  border: none;
  position: static;
  overflow: auto;
  width: 100%;
}
@media screen and (max-width: 768px) {
  ul._1p3i4Cxjxu li.m0j7rKp8e- > a,
  ul._1p3i4Cxjxu li.m0j7rKp8e- > button {
    padding: 1.5rem 0;
  }
}
ul._1p3i4Cxjxu hr {
  height: 0.35rem;
  margin: 0;
}
._21OlKuPRKJ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-top: 0.35rem solid #efefef;
}
._17s848IRbJ {
  margin-right: 0.5rem;
}
._3PnlJLIGaW {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._3PnlJLIGaW b {
  margin-left: 0.25rem;
}
