._37VvUVaFB0 {
  margin-top: 2rem;
}
._3h5kQxAImR {
  height: 4rem;
  width: 3.5rem;
}
._2XqTxKCzkJ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
._6u1TkUqdSx {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding-left: 1rem;
}
.HOtGM1N3jG {
  margin-top: calc(3rem - 0.570724rem);
  margin-bottom: calc(1rem - 0.570724rem);
  color: #000;
  font-weight: 800;
  font-size: 1.57rem;
  margin: 0;
}
._3rpfUU5Zfp {
  color: #686868;
}
._35jMZZCLmO {
  font-weight: 600;
}
._35jMZZCLmO:after {
  content: ':';
  padding-right: 0.25rem;
}
