._2AqEny-bV4 {
  margin-top: 0;
}
._1OyZOxy9gr {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 3rem;
}
._3K8QYbheg2 {
  margin: 0;
}
._3iBL9-1d8c,
.UjPzhIq-LG {
  display: block;
}
._3iBL9-1d8c {
  color: #868686;
}
._3iBL9-1d8c ._3XrSfBMV82 {
  padding: 0 0.5rem;
}
.UjPzhIq-LG {
  margin-bottom: calc(1.5rem - 0.570724rem);
}
.DphIPwPwSL {
  margin: 3rem 0;
}
._3LvOT5PUeJ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 2rem;
}
._3LvOT5PUeJ img {
  max-width: 6rem;
  max-height: 6rem;
  width: 100%;
  height: auto;
  object-fit: contain;
}
