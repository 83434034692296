._3t_zY_FlpO {
  color: #868686;
  height: 3rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  ._3t_zY_FlpO {
    overflow: auto;
    margin-right: -1rem;
    scrollbar-width: none;
  }
  ._3t_zY_FlpO::-webkit-scrollbar {
    display: none;
  }
}
._3ucqjIqaac {
  border-bottom: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 3rem;
}
