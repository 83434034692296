._2nbb7SEwWK {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
._2nbb7SEwWK ._3jLXLqpwFz {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  color: #868686;
  font-weight: normal;
}
._2nbb7SEwWK ._2kkSXmC-la {
  border-bottom: none;
}
