._2SSkZE_8J- {
  background: #fff;
  border-radius: 6px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.IbQ7osA4o6 {
  top: 50%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2020;
  width: 60%;
  max-height: 80vh;
}
@media screen and (min-width: 769px) {
  .IbQ7osA4o6 {
    min-width: 500px;
    max-width: 780px;
  }
}
@media screen and (max-width: 768px) {
  .IbQ7osA4o6 {
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    height: auto;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    max-width: initial;
    max-height: initial;
    border: 0;
    border-radius: 0;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
._1Yjqwsqm2H {
  border: 1px solid #dfdfdf;
  max-width: 800px;
}
._3ql6UyYuJR {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 3rem 4rem;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  ._3ql6UyYuJR {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    padding: 2rem 2rem;
  }
}
._3ql6UyYuJR > div:first-child {
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  margin-right: 1rem;
}
._3ql6UyYuJR > div:first-child span {
  margin-top: calc(0rem - 0.570724rem);
  margin-bottom: calc(0rem - 0.570724rem);
  display: block;
}
._3ql6UyYuJR h1 {
  line-height: 1.2;
  margin-top: calc(0rem - 0.408rem);
}
._3ql6UyYuJR h1:last-child {
  margin-bottom: calc(0rem - 0.408rem);
}
._3ql6UyYuJR h1:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.408rem);
}
._3ql6UyYuJR ._1NFR4KMf0D {
  width: 170px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  ._3ql6UyYuJR ._1NFR4KMf0D {
    margin-bottom: 2rem;
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
._1KWi-vnIYv {
  height: 1px;
  width: 18%;
  background-color: #dfdfdf;
  margin-left: 4rem;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-bottom: -4px;
}
@media screen and (max-width: 768px) {
  ._1KWi-vnIYv {
    margin-left: 2rem;
  }
}
._3RspEYcF6B {
  padding: 3rem 4rem;
  overflow-x: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media screen and (min-width: 769px) {
  ._3RspEYcF6B {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  ._3RspEYcF6B {
    padding: 2rem 2rem;
  }
}
._3MRD1B1Iw8 {
  margin-top: calc(0rem - 0.570724rem);
  margin-bottom: calc(0rem - 0.570724rem);
}
._2YqP9yqf8E {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-top: 1px solid #dfdfdf;
  padding: 1.5rem;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._2YqP9yqf8E > div:first-child {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: #868686;
}
@media screen and (max-width: 768px) {
  ._2YqP9yqf8E > div:first-child {
    text-align: center;
  }
}
._2YqP9yqf8E > div:first-child div > span {
  display: block;
}
._2YqP9yqf8E > div:first-child > span {
  position: relative;
  bottom: 1px;
  margin-right: 1rem;
}
._2YqP9yqf8E > div:last-child {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  ._2YqP9yqf8E > div:last-child {
    margin-bottom: 0.75rem;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -moz-box-align: stretch;
    -o-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }
  ._2YqP9yqf8E > div:last-child button:not(:last-child) {
    margin-right: 0;
    margin-right: 0.75rem;
  }
}
@media screen and (max-width: 768px) {
  ._2YqP9yqf8E {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
._22ltG-pIfD {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 2020;
}
