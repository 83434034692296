._2kSsRuN1-_ {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
._2kSsRuN1-_ ._3G1AvIlkPs {
  font-weight: 600;
}
._2kSsRuN1-_ ._3G1AvIlkPs::after {
  display: none;
}
._2kSsRuN1-_._1u7Apgw5Ht ._3zxpvrtTCx::after {
  position: relative;
  top: 1px;
  content: '*';
  font-size: 1.286rem;
  display: inline-block;
  line-height: 1;
  color: #ff5c5c;
}
._2kSsRuN1-_:not(._1Ls3UzPUgW)._1u7Apgw5Ht ._3zxpvrtTCx::after {
  margin-left: 0.1rem;
}
._3zxpvrtTCx {
  display: block;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  width: 100%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0.25rem;
}
._3zxpvrtTCx ._3G1AvIlkPs {
  display: inline-block;
}
._3zxpvrtTCx ._3YvYeftC9V {
  position: relative;
  top: 2px;
  display: inline-block;
  text-decoration: none;
}
._2OR9Kaw91v {
  font-weight: 500;
  color: #868686;
}
._67IwSZkadH {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
}
._1CavvFkvyU {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -o-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
  margin-top: calc(0.5rem - 0.570724rem);
  margin-bottom: -0.5rem;
}
._3zVCww-n-D {
  display: block;
  font-size: 0.9rem;
  height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
._3zVCww-n-D span {
  display: inline-block;
}
._3zVCww-n-D ._3FVHI6ktVU {
  font-weight: 600;
}
._3zVCww-n-D._3dSCKyNor3 {
  height: auto;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
._3zVCww-n-D ._3xefsl1A0O {
  font-size: 1.15rem;
  margin-right: 0.25rem;
  vertical-align: text-top;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
._1hpR_Cnl52 {
  color: #ff5c5c;
}
._3O3z6gqcwy {
  color: #d18100;
}
.IoT1LMmN-q .l0F3zQb_OG {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
