._51EgsdRZxT {
  width: 100%;
}
._1kv5UJDW2P {
  width: 100%;
  height: 1rem;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
}
._2766IyTbcC {
  background-color: #3d73ff;
  border: 1px solid #fff;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.12s;
  -moz-transition: width 0.12s;
  -o-transition: width 0.12s;
  -ms-transition: width 0.12s;
  transition: width 0.12s;
}
._3fq69ZON-C {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
._3fq69ZON-C div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
