.LyL64WJ7Jp,
._3j-AinsUtN {
  text-align: center;
}
.DTDC-u6FoH {
  padding: 4rem 0 6rem;
}
._157_PZ7kCN {
  padding: 0 0 6rem;
}
@media screen and (max-width: 1080px) {
  ._157_PZ7kCN {
    padding-bottom: 0;
  }
}
.LyL64WJ7Jp {
  font-size: 2.285rem;
}
._3j-AinsUtN {
  font-size: 1.286rem;
  font-weight: 400;
}
._2iPiZosAB1 {
  padding: 2.5rem 1rem;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transform: scale(1.0001);
  -moz-transform: scale(1.0001);
  -o-transform: scale(1.0001);
  -ms-transform: scale(1.0001);
  transform: scale(1.0001);
  max-width: 26rem;
  background: #fff;
  margin: 0 auto 4rem;
  -webkit-box-shadow: 0 4px 35px 0 rgba(0,0,0,0.04), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  box-shadow: 0 4px 35px 0 rgba(0,0,0,0.04), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  border-radius: 25px;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -moz-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -moz-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -o-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -o-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  -ms-transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), -ms-transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.01, 0.07, 1), transform 0.4s cubic-bezier(0.25, 0.01, 0.07, 1);
}
@media screen and (max-width: 768px) {
  ._2iPiZosAB1 {
    -webkit-box-shadow: 0 4px 55px 0 rgba(0,0,0,0.2), inset 0 -1px 0 0 rgba(0,0,0,0.11);
    box-shadow: 0 4px 55px 0 rgba(0,0,0,0.2), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  }
}
._2iPiZosAB1 > div {
  margin-top: -4%;
}
._2iPiZosAB1 > span {
  font-size: 1.57rem;
  line-height: 1.4;
  font-weight: 800;
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  color: #3d73ff;
  font-weight: normal;
  margin-top: -0.75rem;
}
._2iPiZosAB1:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 4px 55px 0 rgba(0,0,0,0.04), inset 0 -1px 0 0 rgba(0,0,0,0.11);
  box-shadow: 0 4px 55px 0 rgba(0,0,0,0.04), inset 0 -1px 0 0 rgba(0,0,0,0.11);
}
._10g0Ua61B8 {
  text-decoration: none;
  display: block;
}
._3rwbRazULJ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.z4j5VStfs- {
  margin-bottom: 4rem;
}
@media screen and (max-width: 1080px) {
  .z4j5VStfs- {
    margin-bottom: 1rem;
  }
}
._13qu6rYkM_ {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  margin: 0 2rem 2rem 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
._3htb6sIQX0 {
  position: relative;
  top: 1px;
}
._2NQP1F5W-z {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  margin-left: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
._2NQP1F5W-z > span {
  display: block;
}
._1K6s-5zoDL {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
._1K6s-5zoDL > span:first-child {
  margin-right: 0.5rem;
}
._2OvspZs_ky {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
}
._32Q8163F6A {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
._6q-pxEf8Tu {
  font-size: 3.428rem;
  font-weight: 800;
}
