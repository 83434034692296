._2O6MW7crKa {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
  margin: 0;
}
._1k7kcHx-GO {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._1k7kcHx-GO:not(:last-child) {
  padding-right: 0.75rem;
}
._1k7kcHx-GO ._2Hbawelq7H {
  color: #868686;
}
._1k7kcHx-GO ._2Hbawelq7H:after {
  content: ':';
}
.yzIDwYQOV8 {
  margin-left: 0.5rem;
}
.Y4gKKzteBN {
  color: #868686;
  font-style: italic;
}
.Y4gKKzteBN:before {
  content: '<';
}
.Y4gKKzteBN:after {
  content: '>';
}
._1uyHotkkhe {
  line-height: 1.9rem;
  border: 1px solid #dfdfdf;
  font-family: 'IBM Plex Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  display: inline;
  background-color: #fff;
  height: 100%;
  padding: 0 0.5rem;
  border-radius: 3px;
}
