._3WeTxf9Zgf {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-transition: background-color 0.12s;
  -moz-transition: background-color 0.12s;
  -o-transition: background-color 0.12s;
  -ms-transition: background-color 0.12s;
  transition: background-color 0.12s;
  height: 100%;
  padding: 0 2rem;
}
._3WeTxf9Zgf:hover {
  background-color: #e6e6e6;
}
._3WeTxf9Zgf.focus-visible {
  background-color: #e6e6e6;
}
._3WeTxf9Zgf:focus {
  outline: 0;
}
._3WeTxf9Zgf:active,
._3WeTxf9Zgf:focus {
  background-color: #d9d9d9;
}
._3N47Lv8bqi {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  margin-right: 0.5rem;
}
._3qo9LdlTlB {
  top: 7rem;
}
._14HMyj9WVf {
  margin-right: -0.4rem;
}
