._1viU_1xt8a {
  margin-bottom: 0.75rem;
}
._2ZoEObwiPy {
  border-left: 1px solid #efefef;
  padding-left: 1rem;
}
._1bxehaBuSD span::first-letter {
  text-transform: uppercase;
}
._3CohJHEtGY {
  margin-bottom: 0.75rem;
}
