._2lHbE5sF5R {
  width: 100%;
  table-layout: fixed;
}
._2lHbE5sF5R tr {
  height: 3rem;
}
._2lHbE5sF5R th {
  text-align: left;
  font-weight: 500;
  color: #868686;
  min-width: 6rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 1080px) {
  ._2lHbE5sF5R {
    margin-bottom: 1.5rem;
  }
}
tr._39qJWEYaSK {
  height: auto;
  vertical-align: bottom;
}
tr._39qJWEYaSK:not(:first-child) {
  height: 3.5rem;
}
tr._39qJWEYaSK th {
  color: #363636;
  font-weight: 600;
  width: 33%;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  tr._39qJWEYaSK th {
    width: 50%;
  }
}
._32g6cpqui5 th {
  padding-left: 1.5rem;
}
.K5rsfJNmLN {
  color: #868686;
}
