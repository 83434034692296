._2-oC09NVp2 {
  border-top: 1px solid #efefef;
  height: 3rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0.5rem;
  color: #868686;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._1_08_j4zL7 {
  text-decoration: underline;
  margin-left: 1rem;
}
._1_08_j4zL7:first-of-type {
  margin-left: 0;
}
._1_08_j4zL7:hover {
  color: #363636;
}
._3Y-eAx64y2 {
  margin-left: 0.5rem;
}
._3Y-eAx64y2:not(:last-child) {
  margin-right: 0.5rem;
}
._1C7Ma8lfGZ:not(:last-child) {
  margin-right: 0.5rem;
}
@media screen and (max-width: 768px) {
  ._3TfATghkGx {
    display: none;
  }
}
._3OB9ffI28H {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
