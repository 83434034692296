.Sx-8lhaBhs {
  margin-top: 0;
}
.Op8oeD6vk- {
  margin-bottom: 2rem;
}
._23WLidEbU- {
  display: block;
  max-width: 12rem;
  margin-bottom: 1.5rem;
}
._3NLrxtSoEq {
  margin-bottom: 0.5rem;
}
