._1iWSAq9v6q {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  padding: 1px;
  text-align: inherit;
  -webkit-transition: color 0.12s ease-in;
  -moz-transition: color 0.12s ease-in;
  -o-transition: color 0.12s ease-in;
  -ms-transition: color 0.12s ease-in;
  transition: color 0.12s ease-in;
}
._1iWSAq9v6q:active,
._39ofANd_Ys {
  color: #363636;
}
._1oAmY1byir {
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -moz-transition: -moz-transform 0.25s ease-in-out;
  -o-transition: -o-transform 0.25s ease-in-out;
  -ms-transition: -ms-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  padding-left: 0.25rem;
}
