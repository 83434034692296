._2vAfk2gJ_s {
  height: 100%;
  width: 100%;
}
._2KcX-OcOgP {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 769px) {
  ._2KcX-OcOgP {
    min-height: calc(100vh - 7rem - 3rem);
  }
}
@media screen and (max-width: 768px) {
  ._2KcX-OcOgP {
    min-height: calc(100vh - 4rem - 3rem);
  }
}
@media screen and (min-width: 769px) {
  .with-sidebar ._2KcX-OcOgP {
    padding-left: 17rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1080px) {
  .with-sidebar ._2KcX-OcOgP {
    padding-left: 3rem;
  }
}
@media screen and (min-width: 769px) {
  .sidebar-minimized ._2KcX-OcOgP {
    padding-left: 3rem;
  }
}
@media screen and (min-width: 769px) {
  .sidebar-transitioned ._2KcX-OcOgP {
    -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -webkit-transition-duration: 330ms;
    -moz-transition-duration: 330ms;
    -o-transition-duration: 330ms;
    -ms-transition-duration: 330ms;
    transition-duration: 330ms;
    -webkit-transition-property: padding-left;
    -moz-transition-property: padding-left;
    -o-transition-property: padding-left;
    -ms-transition-property: padding-left;
    transition-property: padding-left;
  }
}
._3Y7eiHi66i {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
._17ujB3amNu {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
._17ujB3amNu:not(._1aAYi3pVGF) {
  padding-bottom: 1rem;
}
._1aAYi3pVGF {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
}
@media screen and (min-width: 769px) {
  ._2VCjKfIE6I {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  ._2VCjKfIE6I {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  ._3Z14HxXENb {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  ._3Z14HxXENb {
    display: none;
  }
}
