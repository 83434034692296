._3_-P2KPnYX {
  display: inline-block;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
  color: #000;
}
.eWihXSyRBZ:before {
  content: '[ ';
}
.eWihXSyRBZ:after {
  content: ' ]';
}
._3vFXbTkLEX:before {
  content: '{ ';
}
._3vFXbTkLEX:after {
  content: ' }';
}
._1MRxkAENIN {
  color: #f5a623;
}
.Doy3Hfutbb {
  color: #57a700;
}
.KC3Yq_4rwA {
  color: #57a700;
}
._2td93JHkCh {
  padding-right: 0.5rem;
}
._2td93JHkCh:after {
  content: ':';
}
._2-cfnYFuWN:not(:last-of-type) {
  padding-right: 0.5rem;
}
._2-cfnYFuWN:not(:last-of-type):after {
  color: #000;
  content: ',';
}
._2g549w8C-9 {
  color: #ff5c5c;
}
