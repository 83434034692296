.A2uQlgMsWt {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.A2uQlgMsWt:not(:last-child) {
  margin-right: 1.5rem;
}
.A2uQlgMsWt:not(:last-child) .eyxZADMQ-b > ._2tUVmc3oTP::after {
  display: block;
}
.A2uQlgMsWt._3fnYPKBF6u {
  position: relative;
}
.A2uQlgMsWt._3fnYPKBF6u:not(:last-child) {
  margin-right: 0;
}
.A2uQlgMsWt._3fnYPKBF6u:not(:last-child) ._2CK6rvD3HM::after {
  display: block;
}
.A2uQlgMsWt._3fnYPKBF6u:not(:last-child) .eyxZADMQ-b > ._2tUVmc3oTP::after {
  display: none;
}
._2CK6rvD3HM {
  position: absolute;
  top: 0;
  left: 1rem;
  width: 1px;
  height: 100%;
  padding-top: calc(0.5rem + 2rem);
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._2CK6rvD3HM::after {
  background: #dfdfdf;
  width: 1px;
  height: 100%;
  border-radius: 1px;
  content: '';
  display: none;
}
.ljAUoXS7-9 ._1UoTneIovu,
.ljAUoXS7-9 .eyxZADMQ-b > ._2tUVmc3oTP {
  font-weight: 600;
}
._1vnnPAJnLC ._1UoTneIovu {
  color: #fff;
  background-color: #3d73ff;
}
._1vnnPAJnLC .eyxZADMQ-b > ._2tUVmc3oTP {
  color: #000;
}
._1vnnPAJnLC .eyxZADMQ-b > .OVUnjqmdhq {
  color: #363636;
}
._2gvTwpkxj4 ._1UoTneIovu {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  background-color: #fff;
  border: 1px solid #868686;
  color: #868686;
}
._2gvTwpkxj4 .eyxZADMQ-b > ._2tUVmc3oTP {
  color: #868686;
}
._2gvTwpkxj4 .eyxZADMQ-b > .OVUnjqmdhq {
  color: #868686;
}
.XSo8756ruF ._1UoTneIovu {
  background-color: #3d73ff;
  color: #fff;
}
.XSo8756ruF .eyxZADMQ-b > ._2tUVmc3oTP {
  color: #363636;
}
.XSo8756ruF .eyxZADMQ-b > .OVUnjqmdhq {
  color: #868686;
}
.XSo8756ruF .eyxZADMQ-b > ._2tUVmc3oTP::after,
.XSo8756ruF ._2CK6rvD3HM::after {
  background: #3d73ff;
}
.XSo8756ruF._23HU2htgoN .eyxZADMQ-b > ._2tUVmc3oTP::after,
.XSo8756ruF._23HU2htgoN ._2CK6rvD3HM::after {
  background: #ff5c5c;
}
.PfJf6akkb5 ._1UoTneIovu {
  background-color: #ff5c5c;
  color: #fff;
}
.PfJf6akkb5 .eyxZADMQ-b > ._2tUVmc3oTP,
.PfJf6akkb5 .eyxZADMQ-b > .OVUnjqmdhq {
  color: #ff5c5c;
}
._1UoTneIovu {
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  text-align: center;
  margin-right: 0.5rem;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -ms-transition: background-color 0.25s;
  transition: background-color 0.25s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._1UoTneIovu ._1yrNbMQDhr {
  position: relative;
  top: 2px;
}
.eyxZADMQ-b {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}
.eyxZADMQ-b ._2tUVmc3oTP {
  position: relative;
  top: 2.5px;
  white-space: nowrap;
  color: #363636;
  padding-right: 1.5rem;
}
.eyxZADMQ-b ._2tUVmc3oTP::after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 100%;
  width: 9999px;
  height: 1px;
  background: #dfdfdf;
  -webkit-transition: background 0.12s;
  -moz-transition: background 0.12s;
  -o-transition: background 0.12s;
  -ms-transition: background 0.12s;
  transition: background 0.12s;
}
.eyxZADMQ-b .OVUnjqmdhq {
  font-size: 0.857rem;
  color: #868686;
  margin-bottom: 1rem;
  margin-top: 0;
  max-width: 10rem;
}
@media screen and (max-width: 620px) {
  .A2uQlgMsWt {
    position: relative;
  }
  .A2uQlgMsWt:not(:last-child) {
    margin-right: 0;
  }
  .A2uQlgMsWt:not(:last-child) ._2CK6rvD3HM::after {
    display: block;
  }
  .A2uQlgMsWt:not(:last-child) .eyxZADMQ-b > ._2tUVmc3oTP::after {
    display: none;
  }
}
