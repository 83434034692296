._2z3PUCsAYm {
  margin-top: 0;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  ._2z3PUCsAYm {
    background: none;
    margin-right: 0;
  }
}
