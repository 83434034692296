._3tdM9scn0Z {
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  -webkit-transition: border-color 0.2s;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -ms-transition: border-color 0.2s;
  transition: border-color 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._3tdM9scn0Z._1AKzydJd8x .ace-ttn .ace_marker-layer .ace_active-line {
  background-color: #fff;
}
._3tdM9scn0Z._1AKzydJd8x .ace-ttn .ace_gutter-active-line {
  background-color: #cdcdcd;
}
._3tdM9scn0Z._1btu6MYhR1 .ace-ttn .ace_marker-layer .ace_active-line,
._3tdM9scn0Z._1btu6MYhR1 .ace-ttn .ace_gutter-active-line,
._3tdM9scn0Z._1btu6MYhR1 .ace-ttn .ace_cursor {
  display: none;
}
._3tdM9scn0Z._1btu6MYhR1 .ace-ttn .ace_bracket {
  border: 0;
}
