.SHqfsv_2GS {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.SHqfsv_2GS img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.SHqfsv_2GS ._8ZfFihtUo- {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 0;
  height: 2.3rem;
  padding: 1rem 0;
}
.SHqfsv_2GS ._8ZfFihtUo- img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS ._8ZfFihtUo- {
    width: 9rem;
    height: 2.1rem;
  }
}
.SHqfsv_2GS ._7T8u3XAzqF {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SHqfsv_2GS ._2Zvn0cjMoO {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 9rem;
}
@media screen and (min-width: 769px) {
  .SHqfsv_2GS ._2Zvn0cjMoO {
    height: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS ._2Zvn0cjMoO {
    height: 2rem;
  }
}
.SHqfsv_2GS ._2gn--A8Qey {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.SHqfsv_2GS:not(._2wQyrbJB6d):not(._4Z1FHuMULn) ._8ZfFihtUo- {
  width: 13rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d):not(._4Z1FHuMULn) ._8ZfFihtUo- {
    width: 12rem;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: calc(1.5rem - .15rem);
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF {
    padding-left: 0.75rem;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF ._8ZfFihtUo- {
  position: relative;
  top: 0.15rem;
  width: 11rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF ._8ZfFihtUo- {
    width: 8rem;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d) ._2Zvn0cjMoO {
  border-right: 1px solid #dfdfdf;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d) ._2Zvn0cjMoO {
    padding-right: 0.75rem;
  }
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d) ._2gn--A8Qey {
    max-width: 6rem;
    height: 2.5rem;
  }
  .SHqfsv_2GS:not(._2wQyrbJB6d) ._2gn--A8Qey img {
    width: 100%;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d) ._2gn--A8Qey img {
  object-position: left;
}
.SHqfsv_2GS._2wQyrbJB6d {
  width: 15rem;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 2rem;
}
.SHqfsv_2GS._2wQyrbJB6d._4Z1FHuMULn ._7T8u3XAzqF {
  border-top: 1px solid #dfdfdf;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  height: auto;
  width: 100%;
}
.SHqfsv_2GS._2wQyrbJB6d._4Z1FHuMULn ._8ZfFihtUo- {
  width: 100%;
  height: auto;
}
.SHqfsv_2GS._2wQyrbJB6d ._2Zvn0cjMoO {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.SHqfsv_2GS._2wQyrbJB6d ._2gn--A8Qey {
  display: block;
  max-height: 3rem;
  max-width: 10rem;
  width: 100%;
}
.SHqfsv_2GS._2wQyrbJB6d ._2gn--A8Qey img {
  object-position: center;
  width: 100%;
}
