._3-tsXg9YS4 {
  border-bottom: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 2.55rem;
}
.Baa8prGwvx {
  margin-top: 6rem;
}
._3w9Qeuzdsh {
  list-style: none;
  padding: 0;
}
._1B5dDBEcwe {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
._1B5dDBEcwe:not(._3QrIdk03rl) {
  margin-bottom: calc(0.75rem - 0.570724rem);
}
._3w9Qeuzdsh ._38QfHqx3OP {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  font-weight: 600;
}
._3w9Qeuzdsh ._1BS8Xsbz_f {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
}
._2WUhEVhy00 {
  border-bottom: 1px solid #efefef;
  margin-top: 1.5rem;
}
._1qp2s7ERdj {
  border-bottom: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 2.55rem;
}
