._1ezPOEKdpB {
  overflow: auto;
  scrollbar-width: none;
}
._35xw_wZ7df {
  margin-top: 1rem;
  text-align: center;
}
._2VmNhZo1uT {
  padding-top: 0;
  padding-bottom: 0;
}
