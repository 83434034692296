._1rWQy0wTSz {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-top: -2rem;
}
._1pdlFK_xsn {
  margin-top: 0;
  margin-left: calc(0.5rem + 5px);
  margin-bottom: calc(0.5rem + 1px);
  margin-right: 0.5rem;
  width: 100%;
  background-color: #f3f3f3;
  color: #000;
  font-size: 0.857rem;
  y-overflow: auto;
  padding: 0.75rem;
  max-height: 100rem;
  color: #363636;
}
._3GGzCjN0j9 {
  margin-right: 0 !important;
}
